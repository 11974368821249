<template>
  <div class="fixed-button">
    <button id="survey">Need Help ?</button>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.fixed-button {
  position: fixed;
  right: -40px;
  bottom: 50%;
  z-index: 50;
  text-align: right;
  transform: rotate(-90deg);
}

.fixed-button button {
  display: block;
  padding: 6px 12px;
  background: linear-gradient(to right, #714fff, #ec4587, #e87331);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
</style>
