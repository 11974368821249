<template>
  <div class="wrapper">
    <div class="title">
      <h3>Frequently Asked Questions</h3>
    </div>
    <div class="questions-wrapper">
      <ul class="faq">
        <li v-for="(item, index) in questionsPornAI" :key="index" class="info">
          <input :id="index" class="info__switch" type="checkbox" />
          <label :for="index" class="info__headline">{{ item.question }}</label>
          <div class="info__body" v-html="item.answer"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { questionsPornAI } from '../../utils/staticData.js'
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
.title {
  text-align: center;
  color: white;
  margin: 50px auto 80px;
  width: max-content;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
}

.questions-wrapper {
  .faq {
    list-style-type: none;
    padding-left: 0px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .info {
    padding: 10px 0;
    margin-bottom: 20px;
  }

  .info__switch {
    display: none;
  }

  .info__body {
    max-height: 0;
    overflow: hidden;
    transition: 0.6s;
    color: #afafaf;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .info__switch:checked ~ .info__body {
    max-height: 160px;
  }
  .info__switch:checked ~ .info__headline::before {
    transform: rotate(45deg) translateY(0px);
  }
  .info__switch:checked ~ .info__headline::after {
    transform: rotate(-45deg) translateY(0px);
  }

  .info__headline {
    display: block;
    position: relative;
    margin-bottom: 15px;
    user-select: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    padding: 0 30px 7px 0;
    cursor: pointer;
    border-bottom: 1px solid #5c5d5f;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 4px;
      right: 10px;
      top: 4px;
      background: #ffffff;
      transition: transform 0.3s ease-in-out;
    }
    &::before {
      transform: rotate(-180deg);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 900px) {
  .title {
    width: auto;
    margin: 40px 0 0 0;
  }
}

@media (max-width: 600px) {
  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .questions-wrapper {
    .info__headline {
      font-size: 16px;
      font-weight: 500;
    }

    .info__body {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
